@import './variables.scss';

@keyframes fadeInAndOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes announcedEntry {
    0% {
        transform: translateY(-100%);
        opacity: 0.1;
    }
    95% {
        transform: translateY(4px);
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes deletingAnimation {
    0% {
        
    }
    50% {
        background-color: $accent_yellow_colour;
    }
    100% {
    }
}

@keyframes errorAppear {
    0% {
        margin-left: -100%;
    }
    100% {
        margin-left: 0%;
    }
}