@import './variables.scss';

.large_water_mark_holder {
    z-index: 0;
    position: absolute;
    top: 0px;
    left: $width__nav;
    bottom: 0px;
    right: 0px;

    display: flex;
    justify-content: center;
    align-items: center;

    .large_water_mark {
        width: $svg_watermark_size;
        height: auto;

        svg {
            width: 100%;
            height: 100%;
            opacity: 0.20;
            filter: grayscale(1);
        }
    }
}