/* Padding */
$padding_4: 80px;
$padding_3: 40px;
$padding_2: 20px;
$padding_1: 10px;

/* Breakpoints */
$breakpoint__desktop: 	1920px;
$breakpoint__laptop: 	1200px;
$breakpoint__tablet:	840px;
$breakpoint__mobile:	576px;

/* Font Sizes */
$font_size_large:       2.6em;
$font_size_medium:      2em;
$font_size_small:       1.6em;
$font_size_extra_small: 1.2em;
$font_size_comment:     1.0em;

/* Colours */
$dark_colour:          #1d1d1d;
$mid_dark_colour:      #262223;
$light_colour:         #FFF;
$placeholder_colour:   #d0d0d0;
$accent_colour:        #6e6658;
$accent_yellow_colour: #A18600;
$button_colour:        #eee;
$button_font_colour:   #333;
$button_border_colour: #ddd;
$button_colour_hover:   $button_border_colour;
$button_border_colour_hover: #ccc;
$table_row_even:       #eaeaea;
$active_green_lighter: #a1cc7c;
$active_green:         #61a229;
$active_green_darker:  #4b7c20;
$active_red_lighter:   #eb616f;
$active_red:           #dc3545;
$active_red_darker:    #af2b38;
$post_header_info:     #b0b0b0;

/* Dimensions */
$width__nav: 15vw;
$width__dashboard_feed: 100%;
$width__dashboard_feed_max: 920px;
$width__change_post__image: 140px;
$site_image_width: 150px;
$login_form_width: 300px;
$post_copy_max_width: $breakpoint__mobile;
$height__change_post__image: $width__change_post__image;
$height__nav_button: $padding_3;
$height__header: $padding_4;
$height__site_toolbar: $padding_3 + $padding_2;
$height__change_post__image_mobile: 50px;
$required_symbol_size: $padding_1 - 2px;

/* Buttons */
$button_toolkit_height: $padding_3;
$button_toolkit_width: $padding_3;
$button_border_width: 1px;

/* SVG */
$svg_watermark_size: 200px;