@import './variables.scss';
@import './mixins.scss';

#tool_button_container {
    button {
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: none;
        border: none;
        height: $button_toolkit_height;
        width: $button_toolkit_width;
        padding: 0px;
        margin: 0px;
        margin-bottom: $padding-2;
    
        svg {
            width: 100%;
            height: 100%;
            
            path, g {
                fill: $accent_yellow_colour;
            }
        }

        @include m {
            height: $button_toolkit_height - 10px;
            width: $button_toolkit_width - 10px;
        }
    }

    button.MutedButton {
        svg {
            path {
                fill: none;
                stroke: $accent_yellow_colour;
            }
        }
    }

    button:last-of-type {
        margin-bottom: 0px;
    }

    button.fullScreenButton {
        @include touch {
            display: none;
        }
    }
}

.btn {
    @include no-touch;
}

.btn {
    a {
        text-decoration: none;
    }

    .button_inner {
        background-color: $button_colour;
        width: fit-content;
        padding: $padding_1 $padding_2;
        color: $button_font_colour;
        font-weight: 400;
        border: $button_border_width solid $button_border_colour;
        cursor: pointer;

        @include s {
            padding: calc($padding_1 / 2) $padding_1;
        }
    
        p {
            text-decoration: none;
            color: $button_font_colour;
            font-size: $font_size_small;
            text-align: center;

            @include s {
                font-size: $font_size_extra_small;
            }
        }
    }
    
    .button_inner:active {
        background-color: $button_colour_hover;
        border-color: $button_border_colour_hover;
    }

    button {
        line-height: 1;
    }

    button:disabled {
        .button_inner {
            background-color: $button_border_colour; 

            p {
                color: $button_border_colour_hover;
            }
        }
    }
}

.btn.bc_back_btn {
    svg {
        width: auto;
        height: $padding_1 + calc($padding_1 / 2);
    }
}

.btn_save_site {
    .button_inner {
        background-color: $active_green_lighter;
        border-color: $active_green;
    }

    .button_inner:active {
        background-color: $active_green;
        border-color: $active_green_darker;
    }

    button:disabled {
        .button_inner {
            cursor: not-allowed;
            background-color: $active_green_darker;
        }
    }
}

.btn_delete_site {
    .button_inner {
        background-color: $active_red_lighter;
        border-color: $active_red;
    }

    .button_inner:active {
        background-color: $active_red;
        border-color: $active_red_darker;
    }

    button:disabled {
        .button_inner {
            cursor: not-allowed;
            background-color: $active_red_darker;
        }
    }
}