@import './imports/reset.scss';
@import './imports/fonts.scss';
@import './imports/mixins.scss';
@import './imports/buttons.scss';
@import './imports/watermark.scss';
@import './imports/animations.scss';
@import './imports/variables.scss';


body {
  background-color: $dark_colour;
}

.app {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;

  .app_layout_column {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;

    .app_layout_column__inner {
      height: 100%;
      position: relative;
      z-index: 1;
    }
  }

  #column_a {
    flex: 1;
    background-color: $dark_colour;
    max-width: $width__nav;
  }

  #column_b {
    flex: 1;
    background-color: $light_colour;

  }

  #content__window {
    position: relative;
    z-index: 10;
    height: inherit;
  }
}

//Navigation
header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .logo_holder {
    padding: $padding_3 $padding_2 $padding_3 $padding_2;

    @include m {
      padding: $padding_2 $padding_1;
    }

    img {
      width: 100%;
    }

    p {
      margin-top: $padding_1;
      color: $light_colour;
      text-transform: uppercase;
      letter-spacing: 8px;
      text-align: center;
      font-size: $font_size_comment;
      @include no-touch;

      @include l {
        letter-spacing: 4px;
        font-size: $font_size_comment - 0.3;
      }

      @include m {
        display: none;
      }
    }
  }

  nav {
    li {
      width: 100%;
      height: $height__nav_button;

      a {
        display: flex;
        height: inherit;
        width: inherit;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        color: $light_colour;
        border-top: 1px solid $accent_colour;
        font-weight: 400;
        font-size: $font_size_small;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        
        background: linear-gradient(to left, $light_colour 50%, $dark_colour 50%);
        background-size: 210% 100%;
        transition: background-position 0.5s ease-in-out, color 0.5s ease-in-out;

        span {
          font-size: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      a:not(.active):hover {
        background: $accent_colour;
      }

      a.active {
        background-position-x: 99%;
        color: $mid_dark_colour;
      }
    }

    li:last-of-type a {
      border-bottom: 1px solid $accent_colour;
    }
  }

  #tool_button_container {
    display: flex;
    flex-direction: column;
    padding: $padding_3;
    justify-content: top;
    align-items: center;

    @include m {
      padding: $padding_2;
    }
  }
}

//Page
.page_loading_holder {
  width: 100%;
  height: $padding_4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

//Dashboard
.dashboard {
  height: 100%;
  width: 100%;

  .dashboard__inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .dashboard__header {
    max-width: $width__dashboard_feed_max;
    width: $width__dashboard_feed;
    margin: $padding_3 0 ($padding_3 + $padding_2) 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 $padding_3;

    @include m {
      padding: 0 $padding_2;
    }

    @include s {
      margin: $padding_3 0;
    }

    .page_title {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h1 {
        font-size: $font_size_large;
        line-height: 30px;
      }

      p, a {
        font-size: $font_size_medium;
        color: $mid_dark_colour;
        line-height: 1.4;
        margin-bottom: $padding_1;
      }

      p {
        margin-top: $padding_2;
      }
      
      a {
        padding-bottom: calc($padding_1 / 2);
        text-decoration-color: $accent_yellow_colour;
        text-decoration-style: solid;
        text-underline-offset: 3px;
      }
    }
  }

  .dashboard__display_area {
    height: 100%;
  }

  .dashboard__display_area,
  .site_details {
    display: flex;
    width: 100%;
    max-width: $width__dashboard_feed_max;
    overflow-y: scroll;
    padding: 0 $padding_3;

    @include m {
      padding: 0 $padding_2;
    }
  }

  .dashboard__feed {
    max-width: $width__dashboard_feed_max;
    width: $width__dashboard_feed;
    height: 100%;

    .feed__latest_changes {
      min-height: 85%;

      @include l {
        min-height: 80%;
      }
    }
    
    .feed__missed_changes {
      display: flex;
      flex-direction: column;
      align-items: center;

      .spinner {
        display: flex;
        align-items: flex-start;
        min-height: 10%;
      }
    } 

    .feed__section_title_holder {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin: $padding_2;

      .section_title_block {
        display: flex;
      }

      .section_title_block__line {
        height: 1px;
        background-color: $accent_colour;
        flex-grow: 1;
      }

      .section_title_block__title {
        border: 1px solid $accent_colour;
        padding: $padding_1 $padding_2;

        @include s {
          width: 80%;
        }

        h3 {
          font-size: $font_size_small;
          font-weight: 700;
          text-align: center;

          @include s {
            font-size: $font_size_extra_small;
          }
        }
      }
    }

    .dashboard__feed_inner {

      .liveContent {
        animation: announcedEntry 1s ease-in-out forwards;
      }

      .changePost {
        width: 100%;
        margin-bottom: $padding_2;
        border: 1px solid $accent_colour;
        background-color: rgba($color: #FFF, $alpha: 0.7);

        a {
          display: block;
          text-decoration: none;
          color: $mid_dark_colour;
        }

        .changePost__inner {
          display: flex;
          flex-direction: row;

          @include s {
            flex-direction: column;
          }

          .Image_Holder {
            width: $width__change_post__image;
            height: $height__change_post__image;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $accent_colour;
            flex-grow: 0;
            flex-shrink: 0;

            @include s {
              width: 100%;
              height: $height__change_post__image_mobile;
            }

            .missing_image {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              
              p {
                color: $button_colour;
                margin-top: $padding_1;
                text-transform: uppercase;
              }
            }

            img {
              width: auto;
              height: 100%;
              object-fit: cover;

              @include s {
                width: 100%;
                height: auto;
              }
            }

            svg {
              height: $width__change_post__image - $padding_3;
              width: $height__change_post__image - $padding_3;
              opacity: 0.3;

              g {
                stroke: $light_colour;
                fill: $light_colour;
              }
            }
          }

          .Copy_Holder {
            padding: 0 $padding_1 $padding_1 $padding_1;
            flex-grow: 1;

            .post_copy_header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              margin-bottom: $padding_1;
              border-bottom: $button_border_width solid $post_header_info;

              p.post_date,
              p.post_domain {
                text-align: right;
                font-weight: 400;
                font-size: $font_size_extra_small;
                padding: calc($padding_1 / 2) 0;
                height: fit-content;
                color: $button_font_colour;
              }
            }
          }

          .post_copy {
            h4 {
              padding-right: $padding_1;
              text-transform: capitalize;
              font-size: $font_size_medium;
              margin-bottom: $padding_1;
            }

            p {
              font-size: $font_size_small;
              line-height: 1;
              max-width: $post_copy_max_width;
            }
          }

          .Type_Holder {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              writing-mode: vertical-lr;
              transform: rotate(180deg);
              text-orientation: mixed;
              text-align: center;
              color: $light_colour;
              padding: calc($padding_1 / 2);
              text-transform: uppercase;

              @include s {
                transform: none;
                writing-mode: unset;
              }
            }
          }
        }
      }

      .changePost_general {
        .Type_Holder {
          background-color: $accent_colour;
        }
      }
      
      .changePost_custom {
        .Type_Holder {
          background-color: $accent_yellow_colour;
        }
      }
    }
  }

  .table_tools_holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: $padding_2;
    margin-bottom: $padding_3;
  }
}

#dashboard__sites {
  .dashboard__display_area {
    flex-direction: column;
  }
}

.dashboard_item_page {
  .dashboard__header {
    display: flex;
    flex-direction: column;
    margin: $padding_2 0 $padding_3 0;

    @include s {
      margin: $padding_3 0;
    }

    .breadcrumbs_holder {
      display: flex;
      flex-direction: row;
      margin-bottom: $padding_3;
      align-items: center;
      justify-content: flex-start;

      .bc_back_btn {
        margin-right: $padding_2;
      }

      .breadcrumb {
        display: flex;
        flex-direction: row;
        
        li {
          margin-right: $padding_1;
          padding-right: $padding_1;
          border-right: $button_border_width solid $button_border_colour;
          position: relative;

          a, p {
            display: inline-block;
            position: relative;
            font-weight: 300;
            font-size: $font_size_extra_small;
            text-transform: capitalize;
            color: $dark_colour;
          }

          a {
            text-decoration-style: solid;
            text-underline-offset: 4px;
            text-decoration-color: $button_border_colour_hover;
          }

          a:hover {
            text-decoration-color: $button_font_colour;
          }
        }

        li:last-of-type {
          border-right: none;
          margin-right: none;
          padding-right: none;
        }
      }
    }

    .page_title {
      h1 {
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
          font-weight: 400;
          font-size: 0.5em;
          background-color: $accent_colour;
          color: $light_colour;
          padding: 0 $padding_1;
          margin-right: $padding_2;
          @include no-touch;
        }
      }
    }
  }

  .site_details {
    display: grid; 
    grid-template-columns: 2fr 5fr;
    grid-template-rows: 1fr;
    grid-template-areas: 
    ". .";
    padding-bottom: $height__site_toolbar;

    @include s {
      padding-bottom: $height__site_toolbar - $padding_1;
    }

    .detail {
      padding: $padding_2;
      font-size: $font_size_small;

      p {
        text-transform: capitalize;
      }
      
      a, p, input {
        font-size: 1em;
        color: $dark_colour;
      }

      a {
        text-decoration-style: solid;
        text-underline-offset: 4px;
        text-decoration-color: $button_border_colour_hover;
        word-break: break-all;
        line-height: 1.4;
      }

      a:hover {
        text-decoration-color: $button_font_colour;
      }

      img {
        width: 100%;
        max-width: $site_image_width;
      }

      .missing_image {
        padding: $padding_2;
        background-color: $accent_colour;
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          text-transform: uppercase;
          color: $button_colour_hover;
          margin-top: $padding_1;
        }

        svg {
          width: 100%;
          height: auto;
          max-width: calc($login_form_width / 4);
          opacity: 0.5;

          g {
            stroke: $light_colour;
            fill: $light_colour;
          }
        }
      }
    }

    .detail:nth-child(odd),
    .sub_detail:nth-child(odd) {
      font-weight: 400;
      border-right: $button_border_colour_hover $button_border_width solid;

      p {
        text-align: center;
      }
    }

    .detail:nth-child(4n-1), .detail:nth-child(4n),
    .sub_detail:nth-child(4n+1), .sub_detail:nth-child(4n+2) {
      background-color: hex-to-rgba($table_row_even, 0.8);
    }

    .detail_title,
    .sub_detail_title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;

      svg {
        width: 100%;
        height: $required_symbol_size;

        g#Field-Required {
          fill: $active_red;
          stroke: $active_red;
        }
      }

      .required_symbol_holder {
        width: $required_symbol_size;
        height: $required_symbol_size;
        margin-left: 3px;
      }
    }

    .detail_data {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .detail_lowercase p {
      text-transform: lowercase;
    }

    .detail_data_custom_holder {
      margin-top: $padding_2;
      display: grid; 
      grid-template-columns: 1fr 2fr;
      grid-template-rows: 1fr;
      grid-template-areas: 
      ". .";
      width: 100%;
    }

    .custom_holder_edit {
      margin-top: 0px;
    }

    .check_input {
      transition: background-color 0.1s;
      cursor: pointer;
    }

    .check_input:active {
      transform: scale(0.9);
    }

    .check_active,
    .check_deactived {
      width: $padding_1 + calc($padding_1 / 2);
      height: $padding_1 + calc($padding_1 / 2);
      border-radius: 100%;
      padding: 0px;
    }

    .check_active {
      background-color: $active_green;
    }

    .check_deactived {
      background-color: $active_red;
    }

    .checking_period_holder {
      display: flex;
      flex-direction: row;

      @include m {
        flex-direction: column;
      }

      p {
        font-size: $font_size_extra_small;
      }

      .checking_from_holder {
        margin-right: $padding_2;

        @include m {
          margin-right: 0px;
          margin-bottom: $padding_2;
        }
      }

      span, label {
        font-weight: 400;
        font-size: 0.9em;
        background-color: $accent_colour;
        color: $light_colour;
        padding: calc($padding_1 / 2) $padding_1;
        margin-right: $padding_1;
        @include no-touch;
      }

      .checking_holder {
        display: flex;
        flex-direction: row;
        font-size: $font_size_extra_small;
      }
    }

    .sub_detail {
      padding: $padding_1;
      font-size: $font_size_extra_small;

      p {
        font-size: 1em;
      }
    }

    .sub_detail_title {
      p {
        font-weight: 400;
      }
    }

    .sub_detail_data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: $padding_2;

      p {
        text-transform: none;
      }
    }

    .detail_input,
    .sub_detail_input {
      padding: 0px;
      display: flex;
      flex-direction: column;

      input, select {
        font-family: inherit;
        font-weight: inherit;
        font-size: 1em;
        height: 100%;
      }

      ::placeholder {
        color: $placeholder_colour;
      }

      .detail_data_custom_holder {
        padding: 0 $padding_2 $padding_2 $padding_2;
      }

      .field_error_message_holder {
        overflow: hidden;
        height: 100%;
        font-size: inherit;
        padding: 0 $padding_2 $padding_2 $padding_2;
      }
    }

    .detail_input {
      input, select {
        padding: $padding_2;
      }
    }

    p.error-message {
      color: $active_red;
      text-transform: none;
      animation: errorAppear 0.5s ease-in-out forwards;
    }

    .sub_detail_input {
      .field_error_message_holder {
        padding: 0 $padding_1 $padding_1 $padding_2;
      }

      input, select {
        padding: $padding_1 $padding_1 $padding_1 $padding_2;
      }
    }

    select {
      @include no-touch
    }

    input:focus, select:focus {
      background-color: hex-to-rgba($accent_yellow_colour, 0.3);
    }

    input[type=datetime-local] {
      height: auto;
      padding: 0;
      font-family: inherit;
      font-weight: 300;
      display: flex;
      justify-content: center;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .uneditable {
      cursor: not-allowed;
      opacity: 0.2;
      @include no-touch;
    }
  }

  .site_button_holder {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    width: 100%;
    min-height: $height__site_toolbar;
    border-top: $button_border_width solid $mid_dark_colour;
    background-color: hex-to-rgba($light_colour, 0.9);

    @include s {
      height: $height__site_toolbar - $padding_1;
    }

    .api_error_message_holder {
      max-width: $width__dashboard_feed_max;
      padding: $padding_2 $padding_3;

      @include m {
        padding: $padding_1 $padding_2;
      }

      p {
        text-align: center;
        font-size: 1.2em;
        line-height: 1.2;
        color: $active_red;
      }
    }

    .site_button_holder_inner {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      padding: 0 $padding_3;
      max-width: $width__dashboard_feed_max;

      @include m {
        padding: 0 $padding_2;
      }

      .keys,
      .buttons {
        display: flex;
      }

      .keys {
        margin-right: $padding_2;
        flex-direction: column;
        
        p {
          font-size: $font_size_comment;
          margin-bottom: calc($padding_1 / 2);

          span {
            color: $active_red;
            font-size: $font_size_small;
            line-height: 0.5;
          }
        }

        p:last-of-type {
          margin-bottom: 0px;
        }
      }

      .buttons {
        flex-direction: row;
      }
    }

    .btn {
      margin-right: $padding_2;
    }

    .btn:last-of-type {
      margin-right: 0px;
    }
  }

  .site_button_holder_inner.with_key {
    justify-content: space-between;
  }

  .site_button_holder_inner.with_errors {
    margin-bottom: $padding_1 + 12.5px;
  }
}

.required_key_message_holder {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;

  .required_symbol_holder {
    margin-right: 3px;

    svg {
      width: $required_symbol_size;
      height: $required_symbol_size;

      g#Field-Required {
        fill: $active_red;
        stroke: $active_red;
      }
    }
  }
}

//Live Indicator
.live_indicator {
  display: flex;
  flex-direction: row;
  align-items: center;

  .live_time {
    min-width: 90px;

    p {
      font-size: $font_size_medium;
      margin: auto;
    }
  }

  .live_indicator_socket_disconnected_icon {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    border: none;
    height: 30px;
    width: 30px;

    svg {
      width: 100%;
      height: 100%;

      path:first-of-type {
        stroke: $accent_yellow_colour;
      }

      path {
        fill: $accent_yellow_colour;
      }
    }
  }
}

.New_Post_Counter_Holder {
  background-color: $accent_yellow_colour;
  margin-left: 10px;
  border-radius: 100%;
  min-width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $light_colour;

  p {
    font-size: $font_size_small;
  }
}

.login_page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .login_page__form_holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $padding_4;

    .logo_holder {
      margin-bottom: $padding_3;
      max-width: $login_form_width - 100px;

      img {
        width: 100%;
        margin-bottom: $padding_1;
      }

      p {
        text-align: center;
        color: $light_colour;
        text-transform: uppercase;
        font-size: $font_size_comment;
        letter-spacing: $padding_1;
        @include no-touch;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: $login_form_width;
      font-size: $font_size_medium;
      align-items: center;

      p.error-message {
        color: $light_colour;
        font-size: $font_size_comment - 0.2em;
        margin-bottom: $padding_2;
        line-height: 1.1;
        text-align: center;
      }

      input {
        margin-bottom: $padding_2;
        color: $light_colour;
        border: $button_border_width solid $accent_yellow_colour;
        padding: $padding_2;
        background: none;
        background-color: $mid_dark_colour;
        width: $login_form_width - ($padding_2 * 2) - ($button_border_width * 2);
      }

      input[type="checkbox" i] {
        position: absolute;
        display: none;
      }
      
      input:focus {
        outline: none;
        background-color: $light_colour;
        color: $dark_colour;
        border-color: $accent_colour;
      }

      input:disabled {
        background-color: $accent_colour;
      }
      
      .trigger_input_holder {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: inherit;
        align-items: center;
      }

      .custom-checkbox {
        position: relative;
        padding-left: calc(25px + $padding_1);
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $light_colour;
        font-size: 1.6rem;
        height: 25px;
      }
      
      .custom-checkbox .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        border: $button_border_width solid $accent_yellow_colour;
      }

      .custom-checkbox input[type="checkbox"]:checked ~ .checkmark {
        background-color: $accent_yellow_colour;
      }
      
      .custom-checkbox .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      .custom-checkbox input[type="checkbox"]:checked ~ .checkmark:after {
        display: block;
      }
      
      .custom-checkbox .checkmark:after {
        left: 7px;
        top: 1px;
        width: 7px;
        height: 13px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      button {
        background-color: $button_colour;
        width: fit-content;
        padding: $padding_1 $padding_2;
        color: $button_font_colour;
        font-weight: 400;
        border: $button_border_width solid $button_border_colour;
      }

      button:active {
        background-color: $button_colour_hover;
        border-color: $button_border_colour_hover;
      }

      button:disabled {
        background-color: $accent_colour;
      }
    }
  }
}

::selection {
  background-color: $accent_yellow_colour;
  color: $light_colour;
}

.table_loading_area {
  width: 100%;
  height: $padding_4;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    height: 16px;
  }
}

.site_table {
  width: 100%;
  border: $button_border_width solid $mid_dark_colour;

  .site_table__header {
    background-color: $mid_dark_colour;
    color: $light_colour;
  }

  .table_columns,
  .row_inner {
    display: grid;
    grid-template-columns: 9fr 1fr;
    grid-template-rows: 1fr;
    gap: 10px 10px;
    grid-template-areas: ". .";

    @include m {
      grid-template-columns: 7fr 3fr; 
      grid-template-areas: ". ."; 
    }

    a {
      text-decoration: none;
      color: $dark_colour;
    }

    a:hover {
      font-weight: 400;

      .check_active,
      .check_deactived {
        border: $mid_dark_colour solid $button_border_width;
      }
    }
  }

  .table_columns__titles,
  .column_rows {
    display: grid; 
    grid-template-columns: 4fr 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 10px 10px; 
    grid-template-areas: ". . . . ."; 

    @include m {
      grid-template-columns: 8fr 2fr; 
      grid-template-areas: ". ."; 
    }
  }

  .table_columns__titles {
    background-color: $mid_dark_colour;
    color: $light_colour;
  }

  .tc_title,
  .tr_data {
    display: flex;
    align-items: center;

    p {
      text-align: center;
      margin: auto;
      padding: $padding_1;
      font-size: $font_size_extra_small;
      text-transform: capitalize;
    }
  }

  .tc_title {
    p {
      font-weight: 400;
    }
  }

  .tr_data {
    overflow: hidden;
    position: relative;

    p {
      font-size: $font_size_small;
      padding: $padding_2 $padding_1;
    }

    .check_active,
    .check_deactived {
      width: $padding_1 + calc($padding_1 / 2);
      height: $padding_1 + calc($padding_1 / 2);
      border-radius: 100%;
      padding: 0px;
    }

    .check_active {
      background-color: $active_green;
    }

    .check_deactived {
      background-color: $active_red;
    }
  }

  .tr_data__url::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    width: $padding_2;
  }

  .tr_data__duration {
    p {
      font-size: $font_size_extra_small - 0.1em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        font-weight: 400;
        background-color: $accent_colour;
        color: $light_colour;
        padding: calc($padding_1/4) calc($padding_1/2);
        margin-bottom: calc($padding_1/4);
        margin-top: calc($padding_1/2);
        @include no-touch;
      }

      span:first-of-type {
        margin-top: 0px;
      }

      span:nth-of-type(2) ~ span {
        margin-bottom: 0px;
      }
    }
  }

  .row:nth-child(odd) {
    background-color: $light_colour;

    .tr_data__url::after {
      background-image: linear-gradient(to right, hex-to-rgba($light_colour, 0), hex-to-rgba($light_colour, 1));
    }
  }

  .row:nth-child(even) {
    background-color: $table_row_even;

    .tr_data__url::after {
      background-image: linear-gradient(to right, hex-to-rgba($table_row_even, 0), hex-to-rgba($table_row_even, 1));
    }
  }

  .tc_title__duration,
  .tr_data__duration,
  .tc_title__frequency,
  .tr_data__frequency,
  .tc_title__type,
  .tr_data__type {
    @include m {
      display: none;
    }
  }

  .tc_title__url,
  .tr_data__url {
    p {
      text-align: left;
      margin: 0px;
      text-transform: none;
    }
  }

  .row.deleting {
    background-color: $button_border_colour;
    animation: deletingAnimation 0.8s infinite;

    .tr_data__url::after {
      background: none !important;
    }
  }

  .column_tools {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .tool {
      margin-right: $padding_2;
      display: flex;
      align-items: center;

      svg {
        height: $padding_2;
        width: auto;
      }
    }

    .tool:last-of-type {
      margin-right: 0px;
    }

    .tool__delete .delete_button:disabled {
      cursor: not-allowed;
    }
  }

  .table__empty_message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $padding_4;

    p {
      font-size: $font_size_small;
    }
  }
}