@import './variables.scss';

@mixin s {
   @media (max-width: #{$breakpoint__mobile}) {
       @content;
   }
}

@mixin s-min {
   @media (min-width: #{$breakpoint__mobile}) {
       @content;
   }
}

// Medium devices
@mixin m {
   @media (max-width: #{$breakpoint__tablet}) {
       @content;
   }
}

// Large devices
@mixin l {
   @media (max-width: #{$breakpoint__laptop}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (max-width: #{$breakpoint__desktop}) {
       @content;
   }
}

//Custom Size
@mixin custom($screen) {
   @media (max-width: $screen+'px') {
       @content;
   }
}

//Touch Screens
@mixin touch {
	@media (hover: none) and (pointer: coarse) {
	    @content;
	}
}

//Landscape Orientation
@mixin landscape {
	@media (orientation: landscape) {
	    @content;
	}
}

//Landscape Orientation && Touch
@mixin landscape_mobile {
	@media (orientation: landscape) and (hover: none) and (pointer: coarse) and (max-width: #{$breakpoint__tablet}) {
	    @content;
	}
}

//No Touch
@mixin no-touch {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@function hex-to-rgba($hex, $alpha) {
    $r: red($hex);
    $g: green($hex);
    $b: blue($hex);
    @return rgba($r, $g, $b, $alpha);
}